import React, { useEffect } from "react";
import OnScroll from "../js/utils/OnScroll";
import SwiperCore, { Autoplay } from "swiper/core";
//import MainMenuController from "../js/MainMenuController";
import "@popperjs/core";

import "../styles/main.scss";

import home_video_1280 from "../videos/home_1280.webm";
import home_video_1280_mp4 from "../videos/home_1280.mp4";

import home_video_768 from "../videos/home_768.webm";
import home_video_768_mp4 from "../videos/home_768.mp4";

import logo from "../images/mamaai_logo--white.svg";

import uzisLogoGray from "../images/clients/uzis-logo_gray.svg";
import ibmLogo from "../images/clients/ibm-logo_gray.svg";
import mrLogo from "../images/clients/mr-logo_gray.svg";
import grifinumLogo from "../images/clients/grifinum-logo_gray.png";
import stratoxLogo from "../images/clients/stratox_enterprises_logo_dark.svg";
import ceskaSporitelnaLogo from "../images/clients/ceska-sporitelna-logo_gray.svg";
import codeNowLogo from "../images/clients/CodeNOW-Tech-Partner-Transparent-4xRetina.png";
import ibmWatsonLogo from "../images/clients/ibm-watson-logo-black.png";

import conversationalAIIcon from "../images/icons/rpa-robotic-process-automation.svg";
import speechIcon from "../images/icons/comment-blog.svg";
import iotIcon from "../images/icons/service-provider.svg";
import omniIcon from "../images/icons/user-network.svg";
import nlIcon from "../images/icons/edit-document.svg";
import appliedAIIcon from "../images/icons/artificial-intelligence-ai.svg";

import aboutOne from "../images/about_us/world-class.svg";
import aboutTwo from "../images/about_us/trustworthy.svg";
import aboutThree from "../images/about_us/research.svg";
import aboutFour from "../images/about_us/team.svg";

import mobilnetLogo from "../images/media/mobilnet_cz.png";
import svetchytreLogo from "../images/media/svetchytre.svg";
import ceskaTelevizeLogo from "../images/media/ceska_televize.svg";
import lidovkyLogo from "../images/media/ln-logo.svg";
import e15Logo from "../images/media/logo-e15-cz.svg";
import czechcrunchLogo from "../images/media/logo-czechcrunch.svg";
import businessinfoLogo from "../images/media/BI-logo-20let-articles.svg";
import uzisLogo from "../images/clients/uzis-logo.svg";
import seznamZpravyLogo from "../images/media/seznam-zpravy.png";
import prgAIAwardsLogo from "../images/media/ai_awards_b.svg";
import wiredLogo from "../images/media/WIRED_logo.png";
import cnnPrimaNewsLogo from "../images/media/CNNPrimaNews.jpeg";
import forbesLogo from "../images/media/forbes_logo.png";

import TeamSection from "../js/TeamSection";
import ProductsSection from "../js/ProductsSection";

import Metadata from "../js/Metadata";
import Navbar from "../js/Navbar";
import Footer from "../js/Footer";
import QuotesCarousel from "../js/QuotesCarousel";
import SalesSection from "../js/SalesSection";
import AiAcademyToast from "../js/layout/ai_academy_toast";

const IndexPage = () => {
    const quotes = [
        {
            heading: "148 years",
            content: "in speech research",
        },
        {
            heading: "26 years",
            content: "in machine translation",
        },
        {
            heading: "109 years",
            content: "in man-machine dialog",
        },
        {
            heading: "5782",
            content: "citations in scientific papers",
        },
        {
            heading: "92",
            content: "patent applications filed",
        },
        {
            heading: "196 years",
            content: "in IBM Research",
        },
        {
            heading: "51 years",
            content: "in academic research",
        },
        {
            heading: "77 years",
            content: "in deep learning",
        },
        {
            heading: "125 years",
            content: "in NLP",
        },
        {
            heading: "33 years",
            content: "in k8s",
        },
    ];

    useEffect(() => {
        SwiperCore.use([Autoplay]);

        new SwiperCore(".client-bar", {
            loop: true,
            updateOnWindowResize: true,
            observer: true,
            speed: 2000,
            slidesPerView: 3,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            breakpoints: {
                320: {
                    slidesPerView: 2,
                },
                1200: {
                    slidesPerView: 3,
                    autoplay: false,
                },
            },
        });

        const onScroll = new OnScroll();
        //const mainMenuController = new MainMenuController();

        return () => {
            onScroll.unmount();
            //mainMenuController.unmount();
        };
    }, []);

    return (
        <div className="container-max-width">
            <Metadata />
            <Navbar />
            <section className="home-landing-section section g-0 section--light">
                <div>
                    <div id="home" className="section__anchor" />
                    <header className="home-landing-section__header logo-header">
                        <div className="logo-header__logo-wrapper">
                            <img
                                className="logo-header__logo"
                                src={logo}
                                alt="The mama AI"
                            />
                        </div>
                        <p className="logo-header__motto">
                            with MAMA, AI is no drama
                        </p>
                    </header>
                    <div className="home-landing-section__video-wrapper">
                        <div
                            className="home-landing-section__video-overlay"
                            id="overlay"
                        />
                        <video
                            className="home-landing-section__video d-none d-lg-block"
                            preload="none"
                            autoPlay
                            muted
                            loop
                            playsInline
                        >
                            <source
                                src={home_video_1280_mp4}
                                type="video/mp4"
                            />
                            <source src={home_video_1280} type="video/webm" />
                        </video>
                        <video
                            className="home-landing-section__video d-lg-none"
                            preload="none"
                            autoPlay
                            muted
                            loop
                            playsInline
                        >
                            <source src={home_video_768_mp4} type="video/mp4" />
                            <source src={home_video_768} type="video/webm" />
                        </video>
                    </div>
                </div>
                <div className="client-bar container pt-2">
                    <h6 className="client-bar__title">
                        Our trusted partners and clients
                    </h6>
                    <div className="swiper-wrapper">
                        <div className="swiper-slide client-bar-item">
                            <img
                                className="client-bar-item__image"
                                src={uzisLogoGray}
                                alt="UZIS logo"
                            />
                            <img
                                className="client-bar-item__image"
                                src={ibmLogo}
                                alt="IBM logo"
                            />
                            <img
                                className="client-bar-item__image"
                                src={ibmWatsonLogo}
                                alt="IBM Watson logo"
                            />
                        </div>
                        <div className="swiper-slide client-bar-item">
                            <img
                                className="client-bar-item__image client-bar-item__image--big"
                                src={stratoxLogo}
                                alt="Stratox Enterprises logo"
                            />
                            <img
                                className="client-bar-item__image client-bar-item__image--big"
                                src={codeNowLogo}
                                alt="CodeNow logo"
                            />
                            <img
                                className="client-bar-item__image"
                                src={ceskaSporitelnaLogo}
                                alt="Česká spořitelna logo"
                            />
                        </div>
                        <div className="swiper-slide client-bar-item">
                            <img
                                className="client-bar-item__image"
                                src={grifinumLogo}
                                alt="Grifinum logo"
                            />
                            <img
                                className="client-bar-item__image"
                                src={mrLogo}
                                alt="Mobilni rozhlas logo"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section
                id="media-section"
                className="media-section section--light"
            >
                <div className="container">
                    <div className="row pb-4 mt-5 text-center">
                        <h1>MAMA in Media</h1>
                    </div>
                    <div className="row px-5 text-center">
                        <div className="pt-4 pb-4 px-4 col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 media-card">
                            <a
                                href="https://www.seznamzpravy.cz/clanek/redakce-seznam-zpravy-muzete-i-poslouchat-az-40-zprav-denne-247309"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="media-link"
                                title=""
                            >
                                <img
                                    className="py-1 media-logo img-responsive"
                                    src={seznamZpravyLogo}
                                    alt="Seznam Zprávy"
                                />
                                <p className="pt-4">
                                    Seznam, in collaboration with MAMA AI, introduces new artificial voices that read Seznam Zprávy news daily
                                </p>
                            </a>
                        </div>
                        <div className="pt-4 pb-4 px-4 col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 media-card">
                            <a
                                href="https://www.wired.cz/clanky/lide-si-k-ai-vytvorili-citovy-vztah-chteli-aby-jim-umely-tomas-volal-denne"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="media-link"
                                title=""
                            >
                                <img
                                    className="py-1 media-logo img-responsive"
                                    src={wiredLogo}
                                    alt="WIRED"
                                />
                                <p className="pt-4">
                                    <b>MAMA AI in WIRED:</b><br/>
                                    Lidé si k AI vytvořili citový vztah. Chtěli, aby jim umělý Tomáš volal denně</p>
                            </a>
                        </div>
                        <div className="pt-4 pb-4 px-4 col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 media-card">
                            <a
                                href="https://cnn.iprima.cz/ai-a-lidstvo-potencial-k-lepsimu-ziti-nebo-hrozba-odbornici-maji-jasno-416849"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="media-link"
                                title=""
                            >
                                <img
                                    className="py-1 media-logo img-responsive"
                                    src={cnnPrimaNewsLogo}
                                    alt="CNN Prima News"
                                />
                                <p className="pt-4">
                                    <b>MAMA AI on CNN Prima:</b><br/>
                                    AI a lidstvo: Potenciál k lepšímu žití, nebo hrozba? Odborníci mají jasno</p>
                            </a>
                        </div>
                        <div className="pt-4 pb-4 px-4 col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 media-card">
                            <a
                                href="https://forbes.cz/pomoc-v-rodinne-krizi-aplikace-od-ivany-tykac-sazi-na-umelou-inteligenci/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="media-link"
                                title=""
                            >
                                <img
                                    className="py-1 media-logo img-responsive"
                                    src={forbesLogo}
                                    alt="Forbes"
                                />
                                <p className="pt-4">
                                    <b>MAMA AI in FORBES:</b><br/>
                                    Pomoc v rodinné krizi. Aplikace od Ivany Tykač sází na umělou inteligenci
                                </p>
                            </a>
                        </div>
                    </div>

                    <div className="row px-5 text-center">
                        <div className="pt-4 pb-4 px-4 col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 media-card">
                            <a
                                href="https://www.aiawards.cz/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="media-link"
                                title=""
                            >
                                <img
                                    className="py-1 media-logo img-responsive"
                                    src={prgAIAwardsLogo}
                                    alt="AI Awards 2023"
                                />
                                <p className="pt-4">
                                    MAMA AI secured a spot in the TOP3 in the AI in Business category of AI Awards 2023 organized by prg.ai</p>
                            </a>
                        </div>
                        <div className="pt-4 pb-4 px-4 col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 media-card">
                            <a
                                href="https://www.seznamzpravy.cz/clanek/tech-ai-umela-inteligence-poznejte-robota-seznam-zpravy-testuji-v-radiu-stroj-ktery-mluvi-jako-clovek-232868"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="media-link"
                                title=""
                            >
                                <img
                                    className="py-1 media-logo img-responsive"
                                    src={seznamZpravyLogo}
                                    alt="Seznam Zprávy"
                                />
                                <p className="pt-4">
                                    MAMA AI pioneers and deploys the first ever Czech virtual moderator Hacsiko in cooperation with Seznam
                                </p>
                            </a>
                        </div>
                        <div className="pt-4 pb-4 px-4 col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 media-card">
                            <a
                                href="https://cc.cz/uci-pocitace-vest-s-lidmi-opravdove-rozhovory-brali-jsme-jako-povinnost-aby-umely-cesky-hlasi-startup-mama-ai/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="media-link"
                                title=""
                            >
                                <img
                                    className="py-1 media-logo img-responsive"
                                    src={czechcrunchLogo}
                                    alt="Mobilenet.cz logo"
                                />
                                <p className="pt-4">
                                    Učí počítače vést s lidmi opravdové
                                    rozhovory. Brali jsme jako povinnost, aby
                                    uměly česky, hlásí startup Mama AI
                                </p>
                            </a>
                        </div>
                        <div className="pt-4 pb-4 px-4 col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 media-card">
                            <a
                                href="https://www.e15.cz/podcasty/e15-cast/rec-je-nejvetsi-lidsky-vynalez-my-ho-chceme-ovladnout-pomoci-stroju-rikaji-zakladatele-the-mama-ai-1382270"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="media-link"
                                title=""
                            >
                                <img
                                    className="py-1 media-logo img-responsive"
                                    src={e15Logo}
                                    alt="Světchytře logo"
                                />
                                <p className="pt-4">
                                    Řeč je největší lidský vynález. My ho chceme
                                    ovládnout pomocí strojů, říkají zakladatelé
                                    The Mama AI
                                </p>
                            </a>
                        </div>
                    </div>

                    <div className="row px-5 text-center">
                        <div className="pt-4 pb-4 px-4 col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 media-card">
                            <a
                                href="https://www.lidovky.cz/relax/lide/strojum-chybi-selsky-rozum-mohou-pocitace-ziskat-vlastni-vedomi-a-ohrozit-lidstvo-jako-ve-sci-fi.A210621_155409_lide_ape?zdroj=zavora"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="media-link"
                                title=""
                            >
                                <img
                                    className="py-1 media-logo"
                                    src={lidovkyLogo}
                                    alt="Lidovky logo"
                                />
                                <p className="pt-4">
                                    Strojům chybí selský rozum. Mohou počítače
                                    získat vlastní vědomí a ohrozit lidstvo?
                                </p>
                            </a>
                        </div>
                        <div className="pt-4 pb-4 px-4 col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 media-card">
                            <a
                                href="https://www.businessinfo.cz/clanky/covid-report-jedinecny-projekt-denne-informuje-miliony-lidi-o-skutecnem-poctu-nakazenych/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="media-link"
                                title=""
                            >
                                <img
                                    className="py-1 media-logo"
                                    src={businessinfoLogo}
                                    alt="Česká televize logo"
                                />
                                <p className="pt-4">
                                    COVID Report: Jedinečný projekt denně
                                    informuje miliony lidí o skutečném počtu
                                    nakažených
                                </p>
                            </a>
                        </div>
                        <div className="pt-4 pb-4 px-4 col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 media-card">
                            <a
                                href="https://data.nzis.cz/news-detail/cs/14-mobilni-rozhlas-covid-report-nova-aplikace-na-otevrenymi-daty/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="media-link"
                                title=""
                            >
                                <img
                                    className="py-1 media-logo"
                                    src={uzisLogo}
                                    alt="UZIS logo"
                                />
                                <p className="pt-4">
                                    Mobilní rozhlas: Covid report – nová
                                    aplikace nad otevřenými daty
                                </p>
                            </a>
                        </div>
                        <div className="pt-4 pb-4 px-4 col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 media-card">
                            <a
                                href="https://www.ceskatelevize.cz/porady/1097181328-udalosti/221411000100416/video/834092"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="media-link"
                                title=""
                            >
                                <img
                                    className="py-1 media-logo"
                                    src={ceskaTelevizeLogo}
                                    alt="Česká televize logo"
                                />
                                <p className="pt-4">
                                    Umělá inteligence pomáhá starostům s
                                    orientací v epidemické situaci
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section id="about-us-section" className="about-us-section section">
                <div id="about-us-section-anchor" className="section__anchor" />
                <div className="container">
                    <div className="row pt-4 pb-5 text-center">
                        <h1>About us</h1>
                    </div>
                    <div className="row py-4 px-4 pt-3 pb-1">
                        <div className="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2">
                            <div className="card card-flip h-100">
                                <div className="card-front">
                                    <div className="card-body text-center">
                                        <img
                                            className="card-img-top"
                                            src={aboutOne}
                                            alt="Card"
                                        />
                                        <h4 className="card-title">
                                            World class AI and Cloud experts
                                            with 25+ years of experience
                                        </h4>
                                    </div>
                                </div>
                                <div className="card-back">
                                    <p className="card-text">
                                        Top-class research, development and
                                        customer solutions in conversational AI,
                                        speech recognition and synthesis, dialog
                                        systems, machine translation, generic
                                        deep learning, machine learning and
                                        beyond.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2">
                            <div className="card card-flip h-100">
                                <div className="card-front">
                                    <div className="card-body text-center">
                                        <img
                                            className="card-img-top"
                                            src={aboutTwo}
                                            alt="Card"
                                        />
                                        <h4 className="card-title">
                                            Trustworthy relationships with
                                            customers and industry partners
                                        </h4>
                                    </div>
                                </div>
                                <div className="card-back">
                                    <p className="card-text">
                                        Large number of succesfull
                                        collaborations with industrial partners
                                        from banking, automotive, agriculture.
                                        Experience from many government projects
                                        in the field of healthcare, education,
                                        defense, security.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2">
                            <div className="card card-flip h-100">
                                <div className="card-front">
                                    <div className="card-body text-center">
                                        <img
                                            className="card-img-top"
                                            src={aboutThree}
                                            alt="Card"
                                        />
                                        <h4 className="card-title">
                                            IBM Watson and IBM Research alumni
                                        </h4>
                                    </div>
                                </div>
                                <div className="card-back">
                                    <p className="card-text">
                                        For many years we have worked as part of
                                        IBM T.J. Watson Research Center in
                                        NY/Prague and in 2014 we co-founded the
                                        IBM Watson Group.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2">
                            <div className="card card-flip h-100">
                                <div className="card-front">
                                    <div className="card-body text-center">
                                        <img
                                            className="card-img-top"
                                            src={aboutFour}
                                            alt="Card"
                                        />
                                        <h4 className="card-title">
                                            Multigenerational team, balanced and
                                            highly-performing
                                        </h4>
                                    </div>
                                </div>
                                <div className="card-back">
                                    <p className="card-text">
                                        In early nineties, some of us worked on
                                        statistical speech recognition while
                                        others learned to speak their first
                                        words. Working together for three
                                        decades, we feel almost like a family.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <QuotesCarousel
                data={quotes}
                title="MAMA all together"
                sectionclassName="quotes-carousel--main"
            />

            <section
                id="how-can-we-help-section"
                className="how-can-we-help-section section section--light"
            >
                <div
                    id="how-can-we-help-section-anchor"
                    className="section__anchor"
                />
                <div className="container">
                    <div className="row pt-5 pb-3 text-center">
                        <h1> Passionate about AI </h1>
                    </div>
                    <div className="row pb-5 py-3 text-center">
                        <div className="py-3 col-12 col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-2">
                            <div className="card h-100">
                                <img
                                    src={conversationalAIIcon}
                                    className="card-img-top"
                                    alt="conversational AI icon"
                                />
                                <div className="card-body">
                                    <h4 className="card-title">
                                        Conversational AI
                                    </h4>
                                    <ul className="card-text">
                                        <li>Assistants and chatbots</li>
                                        <li>Design for voice and text</li>
                                        <li>Deflection of common tasks</li>
                                        <li>Customer sentiment</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="py-3 col-12 col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-2">
                            <div className="card h-100">
                                <img
                                    src={speechIcon}
                                    className="card-img-top"
                                    alt="speech icon"
                                />
                                <div className="card-body">
                                    <h4 className="card-title">Speech</h4>
                                    <ul className="card-text">
                                        <li>Call center transcriptions</li>
                                        <li>Call logs analytics</li>
                                        <li>Speaker id and verification</li>
                                        <li>Mood detection</li>
                                        <li>Agent guidance</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="py-3 col-12 col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-2">
                            <div className="card h-100">
                                <img
                                    src={appliedAIIcon}
                                    className="card-img-top"
                                    alt="applied AI icon"
                                />
                                <div className="card-body">
                                    <h4 className="card-title">Applied AI </h4>
                                    <ul className="card-text">
                                        <li>
                                            Accoustic monitoring and prediction
                                        </li>
                                        <li>Predictive maintenance</li>
                                        <li>AIOps</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="py-3 col-12 col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-2">
                            <div className="card h-100">
                                <img
                                    src={iotIcon}
                                    className="card-img-top"
                                    alt="iot icon"
                                />
                                <div className="card-body">
                                    <h4 className="card-title">
                                        Edge/IoT/Hybrid
                                    </h4>
                                    <ul className="card-text">
                                        <li>
                                            NLP and Voice on embedded platforms
                                        </li>
                                        <li>Gaming</li>
                                        <li>Automotive</li>
                                        <li>Remote/offline use</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="py-3 col-12 col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-2">
                            <div className="card h-100">
                                <img
                                    src={omniIcon}
                                    className="card-img-top"
                                    alt="omni icon"
                                />
                                <div className="card-body">
                                    <h4 className="card-title">
                                        Omni-channel Interaction
                                    </h4>
                                    <ul className="card-text">
                                        <li>
                                            Interactive customer notifications
                                        </li>
                                        <li>Upsell/Cross-sell</li>
                                        <li>User profiles</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="py-3 col-12 col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-2">
                            <div className="card h-100">
                                <img
                                    src={nlIcon}
                                    className="card-img-top"
                                    alt="natural language icon"
                                />
                                <div className="card-body">
                                    <h4 className="card-title">
                                        Natural Language Generation
                                    </h4>
                                    <ul className="card-text">
                                        <li>
                                            Written or spoken reports from
                                            structured data
                                        </li>
                                        <li>Customized to end-customer</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {<ProductsSection />}
            {<TeamSection />}
            {<SalesSection />}
            {<AiAcademyToast />}
            {<Footer />}
        </div>
    );
};

export default IndexPage;
